.s-cms-content {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    @include mq($from: desktop) {
        margin-bottom: 30px;
    }

    & + & {
        margin-top: 30px;
        @include mq($from: desktop) {
            margin-top: 40px;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    p {
        margin: 20px 0 0;

        &:first-child {
            margin-top: 0;
        }
    }

    &--center {
        p {
            text-align: center;
        }

        h3 {
            text-align: center;
        }
    }

    strong {
        font-weight: 700;
    }
    
    a:not([class]) {
        text-decoration: underline;
        color: $color-dark-blue;
        transition: color 0.3s ease;

        &:hover {
            color: $color-blue;
            transition: color 0.3s ease;
        }
    }

    img, video, figure {
        max-width: 864px;
        height: auto;
        display: block;
        margin: 20px auto;
        width: 100%;
        border-radius: 16px;
        @include mq($from: desktop) {
            border-radius: 40px;
        }

        &.is-native {
            max-width: 100%;
        }
    }

    figure > img {
        width: 100%;
        height: auto;
        display: block;
        margin: 0;
    }

    ul:not([class]), ol:not([class]), .s-cms-2-column-list {
        padding-left: 25px;
        margin: 10px 0 20px;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @include mq($from: desktop) {
            margin: 30px 0;
            padding-left: 30px;
        }

        li {
            margin-bottom: 5px;
        }
    }

    ol:not([class]) {

        li {
            
            &::marker {
                font-weight: 700;
            }
        }
    }
}