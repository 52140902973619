.c-benefits {
    &__list {
        display: flex;
        margin-top: -14px;
        margin-left: -14px;
        width: calc(100% + 14px);
        flex-wrap: wrap;
        @include mq($from: tablet) {
            margin-top: -27px;
            width: calc(100% + 27px);
            margin-left: -27px;
        }
    }

    &__item {
        margin-bottom: 14px;
        margin-left: 14px;
        background: $color-sky-blue;
        box-shadow: 0px 4px 8px rgba(4, 102, 200, 0.1);
        padding: 32px 10px 30px;
        width: calc(50% - 14px);
        @include mq($from: tablet) {
            margin-top: 27px;
            margin-left: 27px;
            width: calc(25% - 27px);
        }
        @include mq($from: desktop) {
            padding: 55px 30px 40px;
        }
    }

    &__img {
        width: 60px;
        height: auto;
        display: block;
        margin: 0 auto;
        @include mq($from: desktop) {
            width: 78px;
        }
    }

    &__text {
        text-align: center;
        font-size: 17px;
        font-weight: 700;
        line-height: 1.29;
        color: $color-dark-blue;
        margin-top: 30px;
        @include mq($from: desktop) {
            margin-top: 45px;
            font-size: 19px;
        }
    }
}