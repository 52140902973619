///* ========================================================================
//   #LAZYLOAD
//   ======================================================================== */

// Classes for lazyloaded images

.lazy {
	opacity: 0;
}
.loaded {
	opacity: 1;
	transition: opacity 300ms;
}