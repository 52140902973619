.c-section {
    padding: 40px 0;
    @include mq($from: desktop) {
        padding: 60px 0;
    }

    &--no-print {
        @media print {
            display: none;
        }
    }

    &__title {
        font-family: $font-aleo;
        font-weight: 400;
        line-height: 1.25;
        margin: 0;
        text-align: center;
        $ifz: 30px;
        $ffz: 40px;
        font-size: $ifz;
        @include mq($from: tablet) {
            font-size: font-increment($ifz, $ffz, 1);
        }
        @include mq($from: desktop) {
            font-size: font-increment($ifz, $ffz, 2);
        }
        @include mq($from: wide) {
            font-size: $ffz;
        }
    }

    &__content {
        margin-top: 20px;

        &:only-child, &:first-child {
            margin-top: 0;
        }
        @include mq($from: desktop) {
            margin-top:  50px;
        }
    }
}