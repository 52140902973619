.c-site-header {
    background-color: $color-white;
    padding: 20px 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;
    @include mq($from: desktop) {
        padding: 15px 0;
    }

    .o-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        width: 185px;
        height: auto;
        display: block;
    }
}

.c-header-phone {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    text-align: center;
    color: $color-black;
    @include mq($from: desktop) {
        flex-direction: row;
        align-items: center;
    }

    &__icon {
        color: $color-dark-blue;
        font-size: 22px;
        @include mq($from: desktop) {
            font-size: 16px;
        }
    }

    &__text {
        font-size: 14px;
        margin-top: 5px;
        line-height: 1;
        @include mq($from: desktop) {
            display: none;
        }
    }

    &__number {
        font-size: 16px;
        margin-left: 9px;
        display: none;
        @include mq($from: desktop) {
            display: block;
        }
    }
}