///* ========================================================================
//   #FOCUS
//   ======================================================================== */

// Declarations of focusable elements

* {

    &:focus {
        outline: none;
    }
    
    &:active {
        outline: none;
    }
}


.is-keyboard-pressed * {

    &:focus {
        outline: 2px solid #ffd027;
    }
}
