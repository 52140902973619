.c-site-footer {
    position: relative;
    width: 100%;
    &__info {
        background-color: $color-sky-blue;
        padding: 28px 0 36px;
        @include mq($from: desktop) {
            padding: 40px 0 34px;
        }

        .o-wrapper {            
            @include mq($from: desktop) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }
    }

    &__logo {
        display: block;
        width: 134px;
        height: auto;
        margin: 0 auto;
        @include mq($from: desktop) {
            width: 168px;
            margin: 0 60px 0 0;
        }
    }

    &__text {
        text-align: center;
        font-size: 16px;
        line-height: 1.37;
        margin-top: 24px;
        @include mq($from: desktop) {
            margin-top: 0;
            text-align: left;
            font-size: 14px;
        }

        p {
            margin: 0;
        }

        &:last-child {
            width: 100%;
            @include mq($from: desktop) {
                margin-top: 24px;
            }
        }
    }

    &__legal {
        background-color: $color-black;
        color: $color-white;
        padding: 20px 0 36px;
        text-align: center;
        @include mq($from: desktop) {
            text-align: left;
            padding: 24px 0 32px;
        }
    }

    &__text-legal {
        line-height: 1.37;
        font-size: 14px;
        @include mq($from: desktop) {
            font-size: 14px;
        }

        strong {
            font-weight: 700;
            font-size: 16px;
            @include mq($from: desktop) {
                font-size: 15px;
            }
        }

        p {
            margin: 0 0 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__to-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        .o-wrapper {            
            position: relative;
        }

        &-button {            
            display: flex;
            width: 36px;
            height: 36px;
            background-color: $color-yellow;
            border-radius: 50%;
            font-size: 20px;
            color: $color-black;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 20px;
            transform: translateY(-50%);
            position: absolute;
            @include mq($from: desktop) {
                width: 50px;
                height: 50px;
                font-size: 27px;
            }
        }
    }
}