.c-contact {
    @include mq($from: desktop) {
        display: flex;
        justify-content: space-between;
    }

    &__info {
        @include mq($from: desktop) {
            width: 280px;
        }
    }

    &__item {
        margin-bottom: 26px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 19px;
        line-height: 1.26;
    }

    &__text {
        margin-top: 10px;
        font-size: 16px;
        line-height: 1.5;
        padding-left: 28px;
        position: relative;

        p {
            margin: 0;
        }

        a {
            color: $color-blue;
            
            &:hover {
                color: $color-dark-blue;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 2px;
        left: 0;
        font-size: 20px;
        color: $color-blue;
    }

    &__map {
        width: 100%;
        max-width: 400px;
        height: auto;
        display: block;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        @include mq($from: desktop) {
            max-width: none;
            width: 600px;
            margin: 0;
        }
    }
}