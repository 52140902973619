.c-hero {
    background-color: $color-white;
    background-image: url('../images/components/hero/hero-bg-sm.webp');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    @include mq($from: desktop) {
        background-image: url('../images/components/hero/hero-bg-lg.webp');
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 154px 20px 144px;
    }

    &__content {
        padding: 44px 0 42px;
        background-color: rgba($color-white, 0.85);
        @include mq($from: desktop) {
            padding: 50px 660px 60px 50px;
            position: relative;
        }
    }

    &__wrapper {
        max-width: 1160px;
        width: 100%;
    }

    &__title {
        font-family: $font-aleo;
        line-height: 1.065;
        font-size: 30px;
        padding: 0 40px;
        @include mq($from: desktop) {
            font-size: 45px;
            padding: 0;
        }

        span {
            color: $color-dark-blue;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 1.25;
        padding: 0 40px;
        margin-top: 14px;
        @include mq($from: desktop) {
            font-size: 22px;
            padding: 0;
        }

        p {
            margin: 0;
        }
    }

    &__pic {
        display: block;
        margin-top: 46px;
        @include mq($from: desktop) {
            width: 700px;
            margin: 0;
            position: absolute;
            right: 0;
            bottom: 30px;
        }

    }

    &__img {
        display: block;
        margin: 0 auto;
        max-width: 360px;
        height: auto;
        @include mq($from: desktop) {
            width: 100%;
            margin: 0;
            max-width: none;
        }
    }
}